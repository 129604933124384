audio { 
    width: 50%; 
    border-radius: 90px;
    transform: scale(1.05);
}

#music_player_container{
    /* position: fixed;
    bottom: 10px; */
}

.musci_player_element{
    
}

.songDeleteButton{
    float: right;
}

.music_icon {
    font-size: 24px;
}

#displaytime{
    float: left;
    display: inline-block;
}

#timeline{
    width: 100%;
    height: 15px;
    margin-top: 5px;
    padding-left: 0;
	background: white;
	float: left;
    border-radius: 15px;
}

#playhead{
	width: 15px;
	height: 15px;
	border-radius: 50%;
    background: rgba(0, 0, 0, 0.82);
    position: relative;
}